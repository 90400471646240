<div class="form-container">
  <div class="form-wrapper">
    <app-header [logoUrl]="logoUrl" [envLogo]="environment.logo"></app-header>
    @if (!requestPending && authCurrentStep === 'login') {
      <div class="language-selection">
        <div class="selection-box">
          @for (item of allLanguages; track item) {
            <p
              class="language-selection"
              [ngClass]="{ light: 1, active: item === currentLocale }"
              (click)="selectLanguage(item)"
              >
              {{ item }}
            </p>
          }
        </div>
      </div>
    }

    @if (!requestPending && authCurrentStep === 'login') {
      <form
        [formGroup]="loginForm"
        (ngSubmit)="loginSubmit()"
        >
        @if (!tenantValues?.tenantId) {
          <lib-tenant-field
            class="app-input"
            [tenantControl]="tenant"
            (emitSelectedTenantData)="receiveSelectedTenant($event)"
            (emitTenantEmpty)="receiveIsTenantEmpty($event)"
          ></lib-tenant-field>
        }
        @if (loginBtnVisible) {
          <mat-form-field appearance="outline">
            <mat-label>{{ 'auth.email' | translate }}</mat-label>
            <input matInput id="email-login" formControlName="email" required />
            @if (email?.hasError('required')) {
              <mat-error>{{
                'auth.errors.email.required' | translate
              }}</mat-error>
            }
            @if (email?.hasError('email')) {
              <mat-error>{{ 'auth.errors.email.incorrect' | translate }}</mat-error>
            }
          </mat-form-field>
        }
        @if (loginBtnVisible) {
          <mat-form-field appearance="outline" class="password-input">
            <mat-label>{{ 'auth.password' | translate }}</mat-label>
            <input matInput [type]="type" formControlName="password" required />
            @if (type === 'password') {
              <mat-icon matSuffix (click)="toggleVisibility('password')"
                >visibility_off</mat-icon
                >
              }
              @if (type !== 'password') {
                <mat-icon matSuffix (click)="toggleVisibility('text')">visibility</mat-icon>
              }
              @if (password?.hasError('required')) {
                <mat-error>{{
                  'auth.errors.password.required' | translate
                }}</mat-error>
              }
            </mat-form-field>
          }
          @if (tenantValues?.tenantId && loginBtnVisible) {
            <button
              type="submit"
              mat-raised-button
              color="primary"
              class="submit-btn"
              [ngClass]="{ light: 1 }"
              [disabled]="loginForm.invalid"
              >
              {{ 'auth.loginButton' | translate }}
            </button>
          }
          @if (registrationForm) {
            <button
              mat-raised-button
              color="primary"
              class="submit-btn"
              [ngClass]="{ light: 1 }"
              routerLink="/registration"
              (click)="registrationClicked()"
              >
              {{ 'auth.register' | translate }}
            </button>
          }
          @if (showAzureId && azureAdLogin) {
            <button
              mat-raised-button
              (click)="loginAzure()"
              color="primary"
              class="azure-login-button"
              >
              {{ 'auth.loginAzure' | translate }}
            </button>
          }
          @if (forgotPassword) {
            <div class="forgotPasswordLinkBox">
              <a (click)="forgotPasswordClicked()"
                ><span>{{ 'auth.forgotPassword' | translate }}</span></a
                >
              </div>
            }
          </form>
        } @else {
          @if (authCurrentStep === 'otp' && !loginSuccess) {
            <div class="step-two" [ngClass]="{ light: 1 }">
              <div class="title">
                {{ 'auth.otpBlock.title' | translate }}
              </div>
              <ng-otp-input
                #ngOtpInput
                (onInputChange)="!requestPending ? onOtpInputChange($event) : null"
                [config]="otpInputConfig"
              ></ng-otp-input>
            </div>
          }
        }

        @if (showUnauthorizedSite) {
          <div class="unauthorizedSiteContainer">
            <div class="unauthorizedSiteTextBox">
              <p class="unauthorizedSiteText">
                {{ 'auth.userHasWrongRoles.text' | translate }}
              </p>
            </div>
          </div>
        }
        <!--PinCode Form  -->

        <!--Registration Form  -->

        @if (authCurrentStep === 'registrationDone') {
          <ng-template #stepRegistrationDone>
            <p class="instructionText" [ngClass]="{ light: 1 }">
              {{ 'auth.registerSuccessText' | translate }}
            </p>
            <button class="submit-button register-button" [ngClass]="{ light: 1 }">
              <span>{{ 'auth.login' | translate }}</span>
            </button>
          </ng-template>
        }
      </div>
    </div>
