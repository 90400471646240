import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthObserver } from './auth-observer';
import { TenantValues } from './model/TenantValues';
import { TenantService } from './tenant.service';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root',
})
export class SSOService {
    tenantId: string;
    tenantValues: {
        keycloakUrl: any;
        tenantId: any;
        adClientId: any;
        tenantConfig: any;
        adRedirectUri?: any;
        adResponseType?: any;
        adScope?: any;
    };
    constructor() {}
    getTenantData(tenantData: any) {
        console.log('Raw tenant data:', tenantData);
        this.tenantValues = {
            keycloakUrl: tenantData.authorizeUrl,
            adClientId: tenantData.ad_clientid,
            adRedirectUri: tenantData.ad_redirecturi || window.location.origin + '/auth',
            adResponseType: tenantData.ad_responsetype || 'code',
            adScope: tenantData.ad_scope || 'openid email profile',
            tenantId: tenantData.tenantId,
            tenantConfig: JSON.parse(tenantData.tenant_config).config[tenantData.tenantId],
        };
        console.log('Mapped tenant values:', this.tenantValues);
        return this.tenantValues;
    }

    async loginWithAzure(tenantValues: TenantValues): Promise<void> {
        try {
            const locale = localStorage.getItem('lang') || 'de';
            const redirectUri = window.location.origin + '/auth';

            // First ensure we have the tenant data
            if (!this.tenantValues) {
                this.tenantValues = tenantValues;
            }

            console.log('Starting Azure login with:', {
                url: this.tenantValues.keycloakUrl,
                realm: this.tenantValues.tenantId,
                clientId: this.tenantValues.adClientId,
                redirectUri,
            });

            // Generate PKCE values
            const { codeVerifier, codeChallenge } = await this.generatePKCE();
            sessionStorage.setItem('code_verifier', codeVerifier);

            // Build authorization URL
            const authUrl = new URL(
                `${this.tenantValues.keycloakUrl}/realms/${this.tenantValues.tenantId}/protocol/openid-connect/auth`
            );

            // Add required OAuth2 parameters
            authUrl.searchParams.append('client_id', this.tenantValues.adClientId);
            authUrl.searchParams.append('redirect_uri', redirectUri);
            authUrl.searchParams.append('response_type', 'code');
            authUrl.searchParams.append('scope', 'openid email profile');

            // Add PKCE parameters
            authUrl.searchParams.append('code_challenge', codeChallenge);
            authUrl.searchParams.append('code_challenge_method', 'S256');

            if (this.tenantValues.tenantConfig?.sso?.active) {
                // SSO is active
                authUrl.searchParams.append('kc_idp_hint', this.tenantValues.tenantConfig.sso.kc_idp_hint);

                // Try to use existing session
                authUrl.searchParams.append('prompt', 'none'); // Attempt silent authentication

                // If silent auth fails, fall back to consent screen without username/password if possible
                authUrl.searchParams.append('max_age', '86400'); // Accept sessions less than 24 hours old
            }

            // Add locale if available
            if (locale) {
                authUrl.searchParams.append('ui_locales', locale);
                authUrl.searchParams.append('kc_locale', locale);
            }

            // Store tenant info for token exchange
            sessionStorage.setItem(
                'azure-info',
                JSON.stringify({
                    authorizeUrl: this.tenantValues.keycloakUrl,
                    tenantId: this.tenantValues.tenantId,
                    ad_clientid: this.tenantValues.adClientId,
                    ad_redirecturi: redirectUri,
                })
            );

            console.log('Redirecting to:', authUrl.toString());

            // Redirect to authorization URL
            window.location.href = authUrl.toString();
        } catch (error) {
            console.error('Error during Azure login:', error);
            throw error;
        }
    }

    private async generatePKCE(): Promise<{ codeVerifier: string; codeChallenge: string }> {
        const codeVerifier = this.generateCodeVerifier();
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const digest = await window.crypto.subtle.digest('SHA-256', data);
        const codeChallenge = this.base64URLEncode(digest);
        return { codeVerifier, codeChallenge };
    }

    private generateCodeVerifier(): string {
        const array = new Uint8Array(32);
        window.crypto.getRandomValues(array);
        return this.base64URLEncode(array);
    }

    private base64URLEncode(buffer: ArrayBuffer): string {
        const uint8Array = new Uint8Array(buffer);
        let binaryString = '';
        for (let i = 0; i < uint8Array.length; i++) {
            binaryString += String.fromCharCode(uint8Array[i]);
        }
        return btoa(binaryString).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }

    static parseJwt(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        return JSON.parse(jsonPayload);
    }
}
