import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { SSOService } from '../../sso.service';
import { environment } from 'apps/swarm/src/environments/environment';
import { Locale } from 'libs/shared/src/lib/models/types/locale';

@Component({
    selector: 'lib-auth-sso',
    template: '<div></div>',
    standalone: true
})
export class AuthSSOComponent implements OnInit {
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _cookieService: CookieService,
        private _ssoService: SSOService,
        private _http: HttpClient
    ) {}

    private redirectToAuth(tenantParam?: string) {
        // Navigate to auth with tenant parameter if provided
        this._router.navigate(['/auth'], {
            queryParams: tenantParam ? { tenant: tenantParam } : {}
        });
    }

    async ngOnInit() {
        try {
            // Get tenant from URL
            const tenantParam = this._activatedRoute.snapshot.queryParamMap.get('tenant');
            if (!tenantParam) {
                console.error('No tenant specified');
                this.redirectToAuth();
                return;
            }

            // Get current locale
            const locale = this._cookieService.get('lang') as Locale || 'de';
            
            // Get tenant data from the DirectoryService
            const tenantUrl = `${environment.authApiUrl}/directory/all?lang=${locale}`;
            this._http.get<any[]>(tenantUrl).subscribe(async (directories) => {
                const tenant = directories.find(d => d.tenantId === tenantParam);
                if (tenant) {
                    // Store necessary tenant data
                    sessionStorage.setItem('azure-info', JSON.stringify(tenant));
                    sessionStorage.setItem('tenant', tenant.tenantId);
                    sessionStorage.setItem('api-url', tenant.apiUrl);
                    
                    // Get properly mapped tenant values and store them
                    const tenantValues = this._ssoService.getTenantData(tenant);
                    sessionStorage.setItem('tenant-values', JSON.stringify(tenantValues));
                    
                    // If Azure AD is configured, try auto-login
                    if (tenantValues.adClientId) {
                        try {
                            await this._ssoService.loginWithAzure(tenantValues);
                        } catch (error) {
                            console.error('Error during Azure login:', error);
                            this.redirectToAuth(tenantParam); // Redirect to normal login on error
                        }
                    } else {
                        console.error('Azure AD not configured for tenant:', tenantParam);
                        this.redirectToAuth(tenantParam); // Redirect to normal login if Azure not configured
                    }
                } else {
                    console.error('Tenant not found:', tenantParam);
                    this.redirectToAuth(); // Redirect to normal login if tenant not found
                }
            }, error => {
                console.error('Error fetching directories:', error);
                this.redirectToAuth(tenantParam); // Redirect to normal login on API error
            });
        } catch (error) {
            console.error('Error during SSO:', error);
            this.redirectToAuth(); // Redirect to normal login on error
        }
    }
}
